<template>
  <div>
    <div class="sctp-container sctp-pad-tb20">
      <div class="" style="margin-top: -10px;">
        <el-breadcrumb separator=">">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>服务中心</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="sctp-bg-white sctp-pad-tb10 sctp-mar-tb10">
        <condition
          @select="onConditionSelect"
          :data="conditionData"
          :value="conditionDefaultValue"
          :label="'name'"
          :data-value="'id'"
        ></condition>
      </div>
      <el-row :gutter="10" class="sctp-mar-t10">
        <el-col :span="18">
          <el-row>
            <el-col :span="24">
              <div class="sctp-pad-tb10 sctp-pad-lr15 sctp-bg-white">
                <div class="sctp-flex sctp-flex-sb">
                  <div class="flex">
                    <el-button-group>
                      <el-button size="mini"
                                 @click="onOrderByClick('')"
                      >
                        综合
                      </el-button>
                      <el-button size="mini"
                                 @click="onOrderByClick('createtime')"
                      >
                        发布时间{{
                          orderByStatus('createtime') === 0 ? '' : orderByStatus('createtime') > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                      <el-button size="mini"
                                 @click="onOrderByClick('serviceprice')"
                      >
                        价格{{
                          orderByStatus('serviceprice') === 0 ? '' : orderByStatus('serviceprice')
                          > 0
                            ? '▲' : '▼'
                        }}
                      </el-button>
                    </el-button-group>
                    <div class="price-input-container">
                      <el-input style="width:120px" size="mini" type="number" v-model="conditionForm.minPrice" @change="blur1">
                        <div slot="prefix">¥</div>
                      </el-input>
                      ~
                      <el-input style="width:120px" size="mini" type="number" v-model="conditionForm.maxPrice" @change="blur2">
                        <div slot="prefix">¥</div>
                      </el-input>
                      <el-button class="mg-l5" @click="getServices" size="mini">确认</el-button>
                    </div>
                  </div>
                  <el-input size="mini" placeholder="服务名称" v-model="conditionForm.serviceName"
                            :style="{width:'auto'}">
                    <el-button @click="() => {pageConfig.page = 1;getServices()}" slot="append"
                               icon="el-icon-search"></el-button>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <template v-for="service in services">
              <el-col :span="8">
                <a :href="'/service/view/' + service.serviceId" target="_blank">
                  <div class="sctp-bg-white sctp-flex sctp-flex-column sctp-mar-t10">
                    <el-image
                      style="width: 100%;height: 200px;"
                      :src="service.covers[0]" fit="cover"
                    ></el-image>
                    <div class="" style="padding: 0 8px 8px;">
                      <div class="sctp-ellipsis sctp-mar-tb5">{{ service.serviceName }}</div>
                      <div class="flex flex-sb fz-12 fc-info">
                        <div>
                          <span>{{ service.subTypeName }}</span>
                        </div>
                        <div>
                          <span>{{ getCity(service.cityCode) }}</span>
                        </div>
                      </div>
                      <div class="divider"></div>
                      <div class="sctp-flex sctp-flex-sb sctp-mar-tb5">
                        <div class="sctp-red">{{
                            service.servicePrice ? `¥${service.servicePrice}` : '免费'
                          }}
                        </div>
                        <div class="">浏览数：{{ service.hits || 0 }}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </el-col>
            </template>
            <el-col :span="24">
              <div class="sctp-pad-tb10 sctp-flex sctp-bg-white sctp-flex-fe">
                <el-pagination
                  :current-page="pageConfig.page"
                  :page-size="pageConfig.limit"
                  @size-change="pageConfig.handleSizeChange"
                  @current-change="pageConfig.handleCurrentChange"
                  :page-sizes="pageConfig.pageSizes"
                  layout="prev, pager, next, total, sizes"
                  :total="pageConfig.total">
                </el-pagination>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="6">
          <div class="sctp-bg-white">
            <div class="sctp-flex sctp-flex-sb sctp-pad-tb15">
              <div class="slider-card-title">推荐服务</div>
              <div></div>
            </div>
            <template v-for="(recommendItem,index) in recommendServiceConfig.data">
              <a target="_blank" :href="'/service/view/' + recommendItem.serviceId"
                 :key="recommendItem.serviceId">
                <div class="">
                  <div class="sctp-flex sctp-wfull sctp-pad5">
                    <el-image
                      class="sctp-mar-r10"
                      :src="recommendItem.covers[0]"
                      fit="cover"
                      style="width:100px;height:100px;"
                    ></el-image>
                    <div class="sctp-flex-item-1">
                      <div class="flex flex-sb flex-col full">
                        <div class="sctp-ellipsis--l3">{{ recommendItem.serviceName }}</div>
                        <div class="sctp-mar-t5"><span>价格：</span><span class="sctp-red">¥{{
                            recommendItem.servicePrice
                          }}</span></div>
                      </div>
                    </div>
                  </div>
                  <template v-if="index !== recommendServiceConfig.data.length - 1">
                    <div class="divider"></div>
                  </template>
                </div>
              </a>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {service} from '@/apis/index'
import {common} from '@/apis'
import area from "@/resources/js/area";

export default {
  components: {
    condition: () => import('@PAGE/home/QueryMenu.vue')
  },
  data() {
    return {
      condition: {
        one: [],
        two: [],
        three: []
      },
      conditionForm: {
        oneType: null,
        twoType: null,
        threeType: null,
        serviceName: null,
        orderBy: null,
        minPrice: null,
        maxPrice: null,
      },
      services: [],
      pageConfig: {
        page: 1,
        limit: 12,
        total: 0,
        pageSizes: [1, 2, 5, 10].map(item => item * 12),
        handleSizeChange: (limit) => {
          this.pageConfig.limit = limit;
          this.getServices()
        },
        handleCurrentChange: (page) => {
          this.pageConfig.page = page;
          this.getServices()
        }
      },
      recommendServiceConfig: {
        data: [],
        /**
         * 获取推荐服务
         * @deprecated
         */
        getRecommendServices: () => {
          service.serviceCenterRecommend()
          .then(res => {
            const {data} = res;
            this.recommendServiceConfig.data = data;
          })
        },
      }
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.path === from.path) {
        this.loadRouterData();
        this.getServices();
      }
    },
    // 'conditionForm.minPrice'(newVaL,oldVal){
    //     if(!(/^\d+$/.test(newVaL)))
    //     {
    //       this.$alert('价格不能包含除数字以外的字符')
    //       this.conditionForm.minPrice=0
    //     }
    // },
    // 'conditionForm.maxPrice'(newVaL,oldVal){
    //   if(!(/^\d+$/.test(newVaL)))
    //   {
    //     this.$alert('价格不能包含除数字以外的字符')
    //     this.conditionForm.maxPrice=0
    //   }
    // },

  },
  methods: {
    blur1(){
      if(this.conditionForm.minPrice!=null) {

        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.conditionForm.minPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.conditionForm.minPrice=0
          return
        }
        let a = this.conditionForm.maxPrice * 1;
        let b = this.conditionForm.minPrice * 1;

        if(b<0)
        {
          this.$alert('价格不能为负数')
          this.conditionForm.minPrice=0
          return
        }
        if(a<b&&this.conditionForm.maxPrice!=null)
        {
          this.$alert("最低价格不能超过最高价格")
          this.conditionForm.minPrice=0
        }
      }
    },
    blur2(){
      if(this.conditionForm.maxPrice!=null) {
        if(!(/^(([^0][0-9]+|0)\.([0-9]{1,2})$)|^(([^0][0-9]+|0)$)|^(([1-9]+)\.([0-9]{1,2})$)|^(([1-9]+)$)/).test(this.conditionForm.maxPrice))
        {
          this.$alert('输入错误！价格精确到2位正小数且不能包含字母')
          this.conditionForm.maxPrice=0
          return
        }

        let a = this.conditionForm.maxPrice * 1;
        if(a<0)
        {
          this.$alert('价格不能为负数')
          this.conditionForm.maxPrice=null
          return
        }
        let b = this.conditionForm.minPrice * 1;
        if(a<b&&this.conditionForm.minPrice!=null)
        {
          this.$alert("最高价格不能低于最低价格")
          this.conditionForm.maxPrice=0
        }
      }
    },
    onOrderByClick(field) {
      let result = null;
      if (field) {
        let ret = this.orderByStatus(field);
        if (ret === 0) {
          result = {field: field, orderByType: 'ASC'};
        } else if (ret === 1) {
          result = {field: field, orderByType: 'DESC'};
        } else if (ret === -1) {
          result = null;
        }
      }
      this.conditionForm.orderBy = result;
      this.getServices();
    },
    getConditions() {
      return this.$request.post({
        reqcode: '1088',
        reqdesc: '条件'
      }).then(res => {
        const {data: {types}} = res;
        const two = types.map(item => item.children || []).reduce((pre, curr) => pre.concat(curr));
        const three = two.map(item => item.children || []).reduce((pre, curr) => pre.concat(curr));
        this.condition = {
          one: types,
          two: two,
          three: three
        }
      })
    },
    getServices() {
      const {pageConfig: {page, limit}, conditionForm} = this;
      return service.serviceCenter({
        page: page,
        limit: limit,
        oneType: conditionForm.oneType ? conditionForm.oneType.id : null,
        twoType: conditionForm.twoType ? conditionForm.twoType.id : null,
        threeType: conditionForm.threeType ? conditionForm.threeType.id : null,
        serviceName: conditionForm.serviceName,
        orderBy: conditionForm.orderBy,
        priceMin: conditionForm.minPrice,
        priceMax: conditionForm.maxPrice,
      }).then(res => {
        const {data = [], count = 0} = res;
        data.forEach(item => {
          if (item.servicecover) {
            item.picture = item.servicecover.split(',')[0]
          }
        });
        this.services = data;
        this.pageConfig.total = count
      })
    },
    onConditionSelect({label, target}) {
      const value = target;
      switch (label) {
        case '一级类目':
          this.conditionForm.oneType = value;
          this.conditionForm.twoType = null;
          this.conditionForm.threeType = null;
          break;
        case '二级类目':
          this.conditionForm.twoType = value;
          this.conditionForm.threeType = null;
          break;
        // case '三级类目':
        //   this.conditionForm.threeType = value;
        //   break;
        default:
          break
      }
      this.getServices()
    },
    loadRouterData() {
      let {search} = this.getQuery();
      this.conditionForm.serviceName = search || null;
    }
  },
  computed: {
    orderByStatus() {
      return function (field) {
        let orderBy = this.conditionForm.orderBy;
        if (orderBy && orderBy.field && orderBy.field === field) {
          if (!orderBy.orderByType || orderBy.orderByType === 'ASC') {
            return 1;
          }
          return -1;
        }
        return 0;
      }
    },
    conditionData() {
      return [
        {
          label: '一级类目',
          values: this.menu('one')
        },
        {
          label: '二级类目',
          values: this.menu('two')
          // },
          // {
          //   label: '三级类目',
          //   values: this.menu('three')
        }
      ]
    },
    conditionDefaultValue() {
      return {
        一级类目: null,
        二级类目: null,
        // 三级类目: null
      }
    },
    menu() {
      return (type) => {
        const {condition, conditionForm} = this;
        let resultArray = [];
        const selectedOne = conditionForm.oneType && conditionForm.oneType.id;
        const selectedTwo = conditionForm.twoType && conditionForm.twoType.id;
        switch (type) {
          case 'one':
            resultArray = condition.one;
            break;
          case 'two':
            if (!selectedOne) {
              resultArray = condition.two
            } else {
              resultArray = conditionForm.oneType.children
            }
            break;
          case 'three':
            // 如果第一分类选中，第二分类显示的是选中的第一分类的子类。第三分类就显示第二分类的合集
            if (selectedOne && !selectedTwo) {
              resultArray = this.menu('two').map(item => item.children || []).reduce(
                (pre, curr) => pre.concat(curr), [])
            } else if (selectedTwo) {
              // 选中第二分类 显示第二分类子类
              resultArray = conditionForm.twoType.children
            } else {
              resultArray = condition.three
            }
            break;
          default:
            break
        }
        resultArray = [{
          name: '全部',
          id: null
        }, ...resultArray];
        return resultArray
      }
    },
    getCity() {
      return function (code) {
        return area.parseCity(code).name;
      }
    },
  },
  mounted() {
    common.viewHistory({
      userId: (this.user && this.user.userId) || null,
      id: 10,
      title: '技术服务大厅',
      type: 10
    });
  },
  beforeMount() {
    this.loadRouterData();
    this.getConditions();
    this.getServices();
    this.recommendServiceConfig.getRecommendServices();
  }
}
</script>

<style scoped lang="scss">
.price-input-container {
  /deep/ {
    .el-input {
      width: 60px;
    }

    .el-input__inner {
      padding: 0 0 0 20px;
    }

    .el-input__prefix {
      div {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.divider {
  height: 1px;
  border-bottom: #dddddd 1px dashed;
  margin-bottom: 8px;
  margin-top: 5px;
  width: 100%;
}
</style>
